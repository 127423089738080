import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { ThemeProvider } from "styled-components";
import theme from "../theme";
import Component from '../components/SubPage/Konditionen';

const Konditionen = () => (
  <ThemeProvider theme={theme}>
    <Layout>
    <Seo title="Auf Dich hören" />
      <Component />
    </Layout>
  </ThemeProvider>
);

export default Konditionen;
