import styled from 'styled-components';

export const SubPageWrapper = styled.div`
  padding: 30px 30px;
  padding-top: 50px;
`;

export const H2 = styled.h2`
  color: black;
  position: relative;
  margin: 0 auto;
  font-weight: 100;
  margin-bottom: 20px;
  margin-top: 50px;
  max-width: 700px;
`;

export const Underline = styled.span`
  text-decoration: Underline;
`;

export const Bold = styled.span`
  font-weight: bold;
`;
